import React from 'react'
import { Typography } from '@mui/material'
function SliderContent({ activeIndex, sliderImage }) {
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? 'slides active' : 'inactive'}
        >
          <img className="slide-image" src={slide.urls} alt="" />
          <div className="slide-title">
            <span
              style={{
                backgroundColor: 'rgba(26, 23, 23, 0.4)',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h1">{slide.title}</Typography>
              <div style={{ width: '70%' }}>
                <Typography gutterBottom sx={{ fontSize: '23px' }}>
                  {slide.description}
                </Typography>
              </div>
            </span>
          </div>
        </div>
      ))}
    </section>
  )
}

export default SliderContent
