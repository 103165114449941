import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import { Chip } from '@mui/material'
const trainingSchedule = [
  {
    id: 1,
    trainingAgeGroupId: 1,
    trainingID: 1,
    startDate: '01/07/2024',
    endDate: '20/07/2024',
    fromTime: '10:00AM',
    toTime: '12:00AM',
    status: 'On Registration',
  },
  {
    id: 2,
    trainingAgeGroupId: 2,
    trainingID: 1,
    startDate: '15/07/2024',
    endDate: '25/07/2024',
    fromTime: '10:00AM',
    toTime: '12:00AM',
    status: 'On Registration',
  },
  {
    id: 3,
    trainingAgeGroupId: 2,
    trainingID: 2,
    startDate: '01/07/2024',
    endDate: '20/07/2024',
    fromTime: '10:00AM',
    toTime: '12:00AM',
    status: 'On Registration',
  },
  {
    id: 3,
    trainingAgeGroupId: 3,
    trainingID: 2,
    startDate: '21/08/2024',
    endDate: '30/08/2024',
    fromTime: '2:00PM',
    toTime: '4:00PM',
    status: 'On Registration',
  },
  {
    id: 4,
    trainingAgeGroupId: 1,
    trainingID: 3,
    startDate: '01/07/2024',
    endDate: '20/07/2024',
    fromTime: '10:00AM',
    toTime: '12:00AM',
    status: 'On Registration',
  },
]

const TrainingSchedule = (props) => {
  const [selectedschedule, setselectedschedule] = useState([{}])

  useEffect(() => {
    const trainingsch = trainingSchedule.filter(
      (trg) =>
        trg.trainingID == props.traingingid &&
        trg.trainingAgeGroupId == props.trainingagegrpid
    )
    setselectedschedule(trainingsch)
    console.log(
      'training id: ',
      props.traingingid,
      'and training group id :',
      props.trainingagegrpid
    )
    console.log(trainingsch)
  }, [props.traingingid, props.trainingagegrpid])

  return (
    <div>
      <Typography
        id="modal-modal-title"
        variant="h5"
        sx={{
          mb: 2,
          fontSize: '1.15rem',
          fontWeight: '400',
          background: '#eaf5fc',
          borderBottom: '2px solid #153c4d',
          padding: '9px 2px',
          marginBottom: '10px',
          borderRadius: '5px 5px 0px 0px',
        }}
      >
        Training Schedule for age group from &nbsp;&nbsp;
        <Chip
          label={`${props.traningagefrom} to ${props.trainingageto}`}
          color="primary"
          sx={{ fontSize: '1.05rem', fontWeight: '400' }}
        />
        &nbsp;&nbsp; on &nbsp;&nbsp;
        <Chip
          label={props.trainingtitle}
          color="primary"
          sx={{ fontSize: '1.05rem', fontWeight: '400' }}
        />
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Traning Start Date</TableCell>
              <TableCell>Training End Date</TableCell>
              <TableCell>From Time </TableCell>
              <TableCell>To Time </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>
                <AppRegistrationIcon />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedschedule.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {row.startDate}
                </TableCell>
                <TableCell>{row.endDate}</TableCell>
                <TableCell>{row.fromTime}</TableCell>
                <TableCell>{row.toTime}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>
                  <Button>Register</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default TrainingSchedule
