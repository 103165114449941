import { Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import TrainingCatagory from './TrainingCatagory'
import TrainingList from './TrainingList'
import { TrainingContext } from '../context/TrainingContext'
const Training = () => {
  const [selectedCat, setselectedCat] = useState({})
  const [TrainingDetailOpen, setTrainingDetailOpen] = useState(false)
  const [TrainingDetailId, setTrainingDetailId] = useState(0)

  return (
    <>
      <TrainingContext.Provider
        value={{
          selectedCat,
          setselectedCat,
          TrainingDetailOpen,
          setTrainingDetailOpen,
          TrainingDetailId,
          setTrainingDetailId,
        }}
      >
        <Container maxWidth="xl" sx={{ marginTop: '100px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  background: '#3684d1',
                  color: 'white',
                  padding: '5px 4px',
                  borderBottom: '2px solid #333',
                }}
              >
                Training Areas
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <TrainingCatagory />
            </Grid>
            <Grid item xs={12} md={10}>
              <TrainingList />
            </Grid>
          </Grid>
        </Container>
      </TrainingContext.Provider>
    </>
  )
}

export default Training
