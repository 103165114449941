import React, { useState } from 'react'
import {
  Typography,
  Container,
  Grid,
  Button,
  Card,
  CardHeader,
  Avatar,
  CardMedia,
  Paper,
} from '@mui/material'
import imgpic from '../images/7.jpg'
import { blue, green } from '@mui/material/colors'

const Welcome = () => {
  const [welcome_msg_short, setWelcome_msg_short] = useState(true)
  const welcome_msg =
    'Wel come to early-brain website. Early brain is a training center that focus on child development, neuro science and parental skills.  The first thousand days of life are a critical and important period of development. Primary care pediatricians and public healthprofessionals play an important role in fostering healthy child development and opportunities to thrive by providing preventive care that promotes safe, stable, and nurturing caregiver relationships.'
  return (
    <Paper
      elevation={3}
      sx={{ width: '85%', margin: '0px auto', padding: '20px' }}
    >
      <Container
        sx={{
          marginTop: '70px',
          textAlign: 'justify',
          marginBottom: '70px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: blue[500],
                textAlign: 'center',
              }}
            >
              Welcome to
              <span
                style={{
                  color: blue[800],
                  textAlign: 'center',
                  fontSize: '40px',
                  paddingLeft: '10px',
                  fontFamily: 'cursive',
                  textShadow: '1px 1px 2px #ccc',
                }}
              >
                Early Brain
              </span>
            </Typography>

            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: blue[500],
                textAlign: 'center',
              }}
            >
              {' '}
              Training Center
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: '400', color: blue[500] }}
            >
              Introduction
            </Typography>
            <Typography
              sx={{ fontSize: '18px', marginBottom: '30px' }}
              variant="body1"
              gutterBottom
            >
              {welcome_msg_short
                ? welcome_msg.slice(0, 400) + '...'
                : welcome_msg}
            </Typography>
            <Button
              variant="contained"
              onClick={() => setWelcome_msg_short(!welcome_msg_short)}
            >
              {welcome_msg_short ? 'Read More' : 'Read Less'}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <img
              width="90%"
              height="100%"
              src={imgpic}
              style={{ borderRadius: '10px', boxShadow: '5px 5px 18px #333' }}
            />
          </Grid>
        </Grid>
      </Container>
    </Paper>
  )
}

export default Welcome
