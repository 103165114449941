import React from 'react'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Stack,
  Button,
} from '@mui/material'
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import LoginIcon from '@mui/icons-material/Login'
import InfoIcon from '@mui/icons-material/Info'
import SegmentIcon from '@mui/icons-material/Segment'
import HomeIcon from '@mui/icons-material/Home'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import PsychologyIcon from '@mui/icons-material/Psychology'
const Header = () => {
  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="ChildDev"
        >
          <PsychologyIcon sx={{ fontSize: '50px' }} />
        </IconButton>
        <Typography variant="h5" sx={{ flexGrow: 0.7 }} xs={12} md={6}>
          Early Brain
        </Typography>

        <Stack direction="row" spacing={2}>
          <Button color="inherit" startIcon={<HomeIcon />}>
            Home
          </Button>
          <Button color="inherit" startIcon={<SegmentIcon />}>
            Training Categories
          </Button>
          <Button color="inherit" startIcon={<InfoIcon />}>
            About
          </Button>
          <Button color="inherit" startIcon={<ContactPhoneIcon />}>
            Contact
          </Button>
          <Button color="inherit" startIcon={<HowToRegIcon />}>
            Register
          </Button>
        </Stack>

        <Button
          color="inherit"
          startIcon={<LoginIcon />}
          sx={{ marginLeft: 'auto' }}
        >
          Login
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default Header
