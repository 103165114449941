import React from 'react'
import Slider from './carousel/Slider'
import { Container } from '@mui/material'
const Carousel = () => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: '10px', marginBottom: '50px' }}>
      <Slider />
    </Container>
  )
}

export default Carousel
