import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { blue, green } from '@mui/material/colors'

import { TrainingContext } from '../context/TrainingContext'

const trainingCategory = [
  {
    id: 1,
    title: 'Life Skills',
    description:
      'Important life skills kids need to know include decision-making skills, problem-solving skills, personal hygiene, meal prep, and communication ...',
  },
  {
    id: 2,
    title: 'Spatial Learning',
    description:
      'Enhancing spatial intelligence in young kids is like exploring a new world. Engage them in puzzles, building blocks, and construction toys.',
  },
  {
    id: 3,
    title: 'Creativity',
    description:
      'Getting out and about into the world with babies and young children can lead to creative moments, and simple things can spark ideas and lead to creative',
  },
  {
    id: 4,
    title: 'Communication',
    description:
      'Teaching communication skills to children is a tall order—to become great communicators, students need positive reinforcement and support from parents',
  },
  {
    id: 5,
    title: 'Confidence',
    description:
      'Encourage them to try new things. One of the best ways to help your child build confidence is to encourage them to try new things. ',
  },
  {
    id: 6,
    title: 'Problem Solving',
    description:
      'Creative thinking is the heart of problem solving. It is the ability to see a different way to do something, generate new ideas, and use materials in new ways.',
  },
  {
    id: 7,
    title: 'Technical Skill',
    description:
      'Technical skill is the heart of problem solving. It is the ability to see a different way to do something, generate new ideas, and use materials in new ways.',
  },
]

// const handleclick=()=>{
//   const trainings = trainingList.filter(
//     (trg) => trg.trainingcatid == selectedCat.id
//   )
// }
const TrainingCatagory = () => {
  const { selectedCat, setselectedCat } = useContext(TrainingContext)
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 500,
        '& ul': { padding: 0 },
        scrollbarWidth: 'none',
      }}
      subheader={<li />}
    >
      <ListSubheader>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            borderBottom: '2px solid #ccc',

            color: '#333',
            marginBottom: '30px',
          }}
        >
          Training Category
        </Typography>
      </ListSubheader>
      {trainingCategory.map((ctg) => (
        <ListItem key={ctg.id} onClick={() => setselectedCat(ctg)}>
          <ListItemText primary={ctg.title} />
        </ListItem>
      ))}
    </List>
  )
}

export default TrainingCatagory
