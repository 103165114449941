import './App.css'
import '@fontsource/roboto/100.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import {
  Avatar,
  Card,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  Paper,
  Typography,
  styled,
} from '@mui/material'
import Header from './components/Header'
import Welcome from './components/Welcome'
import Carousel from './components/Carousel'
import NewTraining from './components/trainingCorousel/NewTraining'
import Training from './components/Training'
function App() {
  return (
    <div className="App">
      <Header />
      <Carousel />
      <Welcome />
      <Training />
    </div>
  )
}

export default App
