import img101 from '../../images/101.jpg'
import img77 from '../../images/77.jpg'
import img100 from '../../images/100.jpg'
import aa from '../../images/aa.avif'
import cc2 from '../../images/cc2.jpg'
import pp from '../../images/pp.jpg'
export default [
  {
    title: 'Life Skills',
    description:
      ' Important life skills kids need to know include decision-making skills, problem-solving skills, personal hygiene, meal prep, and communication ...',
    urls: img101,
  },
  {
    title: 'Spatial Learning',
    description:
      'Enhancing spatial intelligence in young kids is like exploring a new world. Engage them in puzzles, building blocks, and construction toys.',
    urls: img77,
  },
  {
    title: 'Creativity',
    description:
      'Getting out and about into the world with babies and young children can lead to creative moments, and simple things can spark ideas and lead to creative',
    urls: img100,
  },
  {
    title: 'Communication',
    description:
      'Teaching communication skills to children is a tall order—to become great communicators, students need positive reinforcement and support from parents',
    urls: aa,
  },
  {
    title: 'Confidence',
    description:
      'Encourage them to try new things. One of the best ways to help your child build confidence is to encourage them to try new things. ',
    urls: cc2,
  },
  {
    title: 'Problem Solving',
    description:
      'Creative thinking is the heart of problem solving. It is the ability to see a different way to do something, generate new ideas, and use materials in new ways.',
    urls: pp,
  },
]
