import React, { useContext, useEffect, useState } from 'react'
import { TrainingContext } from '../context/TrainingContext'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Paper,
  Modal,
} from '@mui/material'
import TrainingDetail from './TrainingDetail'

const trainingList = [
  {
    id: 1,
    title: 'How to study',
    description:
      'Description of how to study The first thousand days of life are a critical and important period of development. Primary care pediatricians and public healthprofessionals play an important role in fostering healthy child development and ... ',
    trainingcatid: 1,
    status: 'active',
    icon: 'https://static.vecteezy.com/system/resources/thumbnails/032/257/185/small/wallpapers-for-the-beautiful-peacock-wallpaper-ai-generated-free-photo.jpg',
  },
  {
    id: 2,
    title: 'Time Management',
    description:
      'Description of Time management days of life are a critical and important period of development. Primary care pediatricians and public healthprofessionals play an important role in fostering healthy child development',
    trainingcatid: 1,
    status: 'active',
    icon: 'https://t4.ftcdn.net/jpg/07/08/47/75/360_F_708477508_DNkzRIsNFgibgCJ6KoTgJjjRZNJD4mb4.jpg',
  },
  {
    id: 3,
    title: 'Mind and Body Coordination',
    description:
      'Description of Mind Body Coordination first thousand days of life are a critical and important period of development. Primary care pediatricians and public healthprofessionals play an important role in fostering healthy child',
    trainingcatid: 2,
    status: 'active',
    icon: 'https://t4.ftcdn.net/jpg/07/40/17/95/240_F_740179545_cmEwXC3HjQAAcULKjdGrbaz6xaxysgl6.jpg',
  },
  {
    id: 4,
    title: 'Memorization',
    description:
      'Description of Mind Body Memorization . Primary care pediatricians and public healthprofessionals play an important role in fostering',
    trainingcatid: 2,
    status: 'active',
    icon: 'https://t3.ftcdn.net/jpg/01/09/66/82/240_F_109668288_f7TiN0G9DF6zeYN598iLdMNsEGVXJFFo.jpg',
  },
  {
    id: 5,
    title: 'Generating New Ideas',
    description:
      'Description of Generating New Ideas The first thousand days of life are a critical and important period of development. Primary care ',
    trainingcatid: 3,
    status: 'active',
    icon: 'https://t4.ftcdn.net/jpg/07/08/43/59/240_F_708435923_RDAfIjCm6tTNbhp2G4yqFoy48WysM8Hq.jpg',
  },
  {
    id: 6,
    title: 'Generating New Ideas',
    description:
      'Description of Generating New Ideas The first thousand days of life are a critical and important period of development. Primary care ',
    trainingcatid: 1,
    status: 'active',
    icon: 'https://t3.ftcdn.net/jpg/07/18/61/82/240_F_718618244_N18nDNQlsPqvkzhB7ngVod6JYcyUwcWf.jpg',
  },
  {
    id: 7,
    title: 'Generating New Ideas',
    description:
      'Description of Generating New Ideas The first thousand days of life are a critical and important period of development. Primary care ',
    trainingcatid: 1,
    status: 'active',
    icon: 'https://t3.ftcdn.net/jpg/07/31/95/30/240_F_731953095_sRQUKbIxbf41IU7rDgSwbxPKW8nuLzZC.jpg',
  },
  {
    id: 8,
    title: 'Generating New Ideas',
    description:
      'Description of Generating New Ideas The first thousand days of life are a critical and important period of development. Primary care ',
    trainingcatid: 3,
    status: 'active',
    icon: 'https://t4.ftcdn.net/jpg/07/41/71/93/240_F_741719394_C9BP3YbiXSJ7WspSDLtKdYxZKKWlf0Jz.jpg',
  },
]

const TrainingList = () => {
  const {
    selectedCat,
    setselectedCat,
    TrainingDetailOpen,
    setTrainingDetailOpen,
    TrainingDetailId,
    setTrainingDetailId,
  } = useContext(TrainingContext)
  const [selectedTrainings, setselectedTrainings] = useState([{}])

  const handleTrainingDetailOpen = (t_id) => {
    setTrainingDetailId(t_id)
    setTrainingDetailOpen(true)
  }
  useEffect(() => {
    const trainings = trainingList.filter(
      (trg) => trg.trainingcatid == selectedCat.id
    )
    setselectedTrainings(trainings)
  }, [selectedCat])

  return (
    <>
      {TrainingDetailOpen ? <TrainingDetail /> : ''}
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box>
              <Paper>
                <Typography
                  variant="h6"
                  sx={{
                    background: '#3684d124',
                    padding: '2px 10px',
                    color: '#333',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#1976d2',
                      fontSize: '1.3rem',
                      fontWeight: '700',
                    }}
                    component="span"
                  >
                    {selectedCat?.title} &nbsp;
                  </Typography>
                  <Typography sx={{ fontWeight: '500' }} component="span">
                    Training Areas
                  </Typography>
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    borderBottom: '2px solid #ccc',
                    background: '#3684d124',
                    padding: '2px 10px',
                    color: '#333',
                    marginBottom: '30px',
                  }}
                >
                  {selectedCat?.description}
                </Typography>
              </Paper>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {selectedTrainings.map((st, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Card sx={{ maxWidth: 345 }}>
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={st.icon}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {st.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {st.description}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          onClick={() => {
                            handleTrainingDetailOpen(st.id)
                          }}
                        >
                          Show More
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default TrainingList
