import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { TrainingContext } from '../context/TrainingContext'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useTheme } from '@mui/material/styles'
import { AppBar, Grid, IconButton, Toolbar } from '@mui/material'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'

import TrainingSchedule from './TrainingSchedule'
//select * from Training,
const trainingDetails = [
  {
    trainingId: 1, //from Training
    trainingTitle: 'How to study', //from Training
    trainingDescription:
      'Description of how to study The first thousand days of life are a critical and important period of development. Primary care pediatricians and public healthprofessionals play an important role in fostering healthy child development and ... ', //from Training
    trainingCat: 'Life Skills', //from Training Category
    trainingAgeGroupId: 1, //from TrainingAgeGroup (1=from age 4-8, 2=from age 9-12, 3= from age 13-17)
    fromAge: 4, //from TrainingAgwGroup
    toAge: 8, //from TrainingAgwGroup
    ageGroupDesc: 'Training tailored for children from age 4 to 8', //from AgeGroup
    trainingAgeGroupDesc:
      'This training focus on the how to study methods for childern aged from 4 to 8.', //from TrainingAgeGroup
    trainingStatus: 'active', //from Training

    trainingIcon:
      'https://static.vecteezy.com/system/resources/thumbnails/032/257/185/small/wallpapers-for-the-beautiful-peacock-wallpaper-ai-generated-free-photo.jpg', //from Training
  },

  {
    trainingId: 2,
    trainingTitle: 'Time Management',
    trainingDescription:
      'Description of Time management days of life are a critical and important period of development. Primary care pediatricians and public healthprofessionals play an important role in fostering healthy child development',
    trainingCat: 'Life Skills',
    trainingAgeGroupId: 2,
    fromAge: 9,
    toAge: 12,
    ageGroupDesc: 'Training tailored for children from age 9 to 12',
    trainingAgeGroupDesc:
      'This training focus on the how to study methods for childern aged from 9 to 12.',
    trainingStatus: 'active',

    icon: 'https://t4.ftcdn.net/jpg/07/08/47/75/360_F_708477508_DNkzRIsNFgibgCJ6KoTgJjjRZNJD4mb4.jpg',
  },
  {
    trainingId: 3,
    trainingTitle: 'Mind and Body Coordination',
    trainingDescription:
      'Description of Mind Body Coordination first thousand days of life are a critical and important period of development. Primary care pediatricians and public healthprofessionals play an important role in fostering healthy child',
    trainingCat: 'Spatial Learning',
    trainingAgeGroupId: 2,
    fromAge: 9,
    toAge: 12,
    ageGroupDesc: 'Training tailored for children from age 9 to 12',
    trainingAgeGroupDesc:
      'This training focus on the how to study methods for childern aged from 9 to 12.',
    trainingStatus: 'active',

    icon: 'https://t4.ftcdn.net/jpg/07/40/17/95/240_F_740179545_cmEwXC3HjQAAcULKjdGrbaz6xaxysgl6.jpg',
  },
  {
    trainingId: 4,
    trainingTitle: 'Memorization',
    trainingDescription:
      'Description of Mind Body Memorization . Primary care pediatricians and public healthprofessionals play an important role in fostering',
    trainingCat: 'Spatial Learning',
    trainingAgeGroupId: 1,
    fromAge: 4,
    toAge: 8,
    ageGroupDesc: 'Training tailored for children from age 4 to 8',
    trainingAgeGroupDesc:
      'This training focus on the how to study methods for childern aged from 4 to 8.',
    trainingStatus: 'active',

    icon: 'https://t3.ftcdn.net/jpg/01/09/66/82/240_F_109668288_f7TiN0G9DF6zeYN598iLdMNsEGVXJFFo.jpg',
  },
  {
    trainingId: 1,
    trainingTitle: 'How to study',
    trainingDescription:
      'Description of how to study The first thousand days of life are a critical and important period of development. Primary care pediatricians and public healthprofessionals play an important role in fostering healthy child development and ... ',
    trainingCat: 'Life Skills',
    trainingAgeGroupId: 2,
    fromAge: 9,
    toAge: 12,
    ageGroupDesc: 'Training tailored for children from age 9 to 12',
    trainingAgeGroupDesc:
      'This training focus on the how to study methods for childern aged from 9 to 12.',
    trainingStatus: 'active',

    trainingIcon:
      'https://static.vecteezy.com/system/resources/thumbnails/032/257/185/small/wallpapers-for-the-beautiful-peacock-wallpaper-ai-generated-free-photo.jpg',
  },
  {
    trainingId: 1,
    trainingTitle: 'How to study',
    trainingDescription:
      'Description of how to study The first thousand days of life are a critical and important period of development. Primary care pediatricians and public healthprofessionals play an important role in fostering healthy child development and ... ',
    trainingCat: 'Life Skills',
    trainingAgeGroupId: 3,
    fromAge: 13,
    toAge: 17,
    ageGroupDesc: 'Training tailored for children from age 13 to 17',
    trainingAgeGroupDesc:
      'This training focus on the how to study methods for childern aged from 13 to 17.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13',
    trainingStatus: 'active',

    trainingIcon:
      'https://static.vecteezy.com/system/resources/thumbnails/032/257/185/small/wallpapers-for-the-beautiful-peacock-wallpaper-ai-generated-free-photo.jpg',
  },
  {
    trainingId: 4,
    trainingTitle: 'Memorization',
    trainingDescription:
      'Description of Mind Body Memorization . Primary care pediatricians and public healthprofessionals play an important role in fostering',
    trainingCat: 'Spatial Learning',
    trainingAgeGroupId: 3,
    fromAge: 9,
    toAge: 13,
    ageGroupDesc: 'Training tailored for children from age 9 to 13',
    trainingAgeGroupDesc:
      'This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.This training focus on memory improvement methods for childern aged from 9 to 13.',
    trainingStatus: 'active',

    icon: 'https://t3.ftcdn.net/jpg/01/09/66/82/240_F_109668288_f7TiN0G9DF6zeYN598iLdMNsEGVXJFFo.jpg',
  },
  {
    trainingId: 2,
    trainingTitle: 'Time Management',
    trainingDescription:
      'Description of Time management days of life are a critical and important period of development. Primary care pediatricians and public healthprofessionals play an important role in fostering healthy child development',
    trainingCat: 'Life Skills',
    trainingAgeGroupId: 3,
    fromAge: 13,
    toAge: 17,
    ageGroupDesc: 'Training tailored for children from age 13 to 17',
    trainingAgeGroupDesc:
      'This training focus on the how to study methods for childern aged from 13 to 17.',
    trainingStatus: 'active',

    icon: 'https://t4.ftcdn.net/jpg/07/08/47/75/360_F_708477508_DNkzRIsNFgibgCJ6KoTgJjjRZNJD4mb4.jpg',
  },
]

const style = {
  position: 'absolute',
  top: '60%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
const TrainingDetail = () => {
  //   const handleOpen = () => setOpen(true)
  const theme = useTheme()
  const { setTrainingDetailOpen, TrainingDetailId } =
    useContext(TrainingContext)
  const [selectedtraining, setselectedtraining] = useState([{}])
  useEffect(() => {
    const trainingdetail = trainingDetails.filter(
      (trg) => trg.trainingId == TrainingDetailId
    )
    setselectedtraining(trainingdetail)
  }, [TrainingDetailId])
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    setTrainingDetailOpen(false)
  }
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    console.log(value)
  }
  console.log(selectedtraining)

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: 'scroll', scrollbarWidth: 'none' }}
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppBar position="static">
                <Toolbar variant="dense">
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                  >
                    <SettingsAccessibilityIcon />
                  </IconButton>
                  <Typography variant="h6" color="inherit" component="div">
                    Training Detail
                  </Typography>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item xs={6}>
              <Typography id="modal-modal-title" variant="h6">
                Training Category
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                {selectedtraining[0]?.trainingCat}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography id="modal-modal-title" variant="h6">
                Training Title
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                {selectedtraining[0]?.trainingTitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Training Description
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                {selectedtraining[0]?.trainingDescription}
              </Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              bgcolor: 'background.paper',
              display: 'flex',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                orientation="vertical"
              >
                {selectedtraining?.map((training, index) => (
                  <Tab
                    value={index}
                    label={`From age ${training.fromAge} to ${training.toAge}`}
                  />
                ))}
              </Tabs>
            </Box>
            <Box sx={{ flex: 7 }}>
              {selectedtraining?.map((training, ke) => (
                <TabPanel value={value} index={ke} dir={theme.direction}>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    sx={{ mb: 2, fontSize: '1.25rem', fontWeight: '400' }}
                  >
                    Age Group :
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 1, mb: 5 }}
                  >
                    {training?.fromAge} to {training?.toAge}
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    sx={{ mb: 2, fontSize: '1.25rem', fontWeight: '400' }}
                  >
                    Age Group Description :
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 1, mb: 5 }}
                  >
                    {training?.ageGroupDesc}
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    sx={{ mb: 2, fontSize: '1.25rem', fontWeight: '400' }}
                  >
                    Training Description for this age group :
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 1, mb: 5 }}
                  >
                    {training?.trainingAgeGroupDesc}
                  </Typography>

                  <TrainingSchedule
                    traingingid={training.trainingId}
                    trainingagegrpid={training.trainingAgeGroupId}
                    traningagefrom={training.fromAge}
                    trainingageto={training.toAge}
                    trainingtitle={training.trainingTitle}
                  />
                </TabPanel>
              ))}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default TrainingDetail
